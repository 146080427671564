import React, {useState } from "react";

import MenuItem from '@material-ui/core/MenuItem';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Delete from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {makeStyles,ThemeProvider,createTheme,} from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import {DateTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import api from '../services/api'
import { parseISO } from 'date-fns';
import { Autocomplete } from '@mui/material';


import {
  MuiPickersUtilsProvider,


} from '@material-ui/pickers'

import 'date-fns'
import DateFnsUtils from "@date-io/date-fns";
import { TextField } from "@material-ui/core";



// SEND TO BD

function updateBD(v,id,sheet,col) {

  
  api.get('update/update.php?value='+v+'&id='+id+'&sheet='+sheet+'&col='+col+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        
          if(result.data==='senha'){
            alert('Senha não autorizada. Por favor escolha outra.');
          }
          
      }});
  
}

const useStyles = makeStyles((theme) => ({

    root: {
      icon: {
        fill: 'white',
     },
     
      "& .MuiInputLabel-formControl": {
        top:"-2px !important"
      },
      "& .MuiPickersMonth": {
        color: 'white'
      },
      
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#555", color: 'white'
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "gray", color: 'white'
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: deepPurple[500], color: 'white'
      }, 
      
    },


    
    padding: {
      padding: theme.spacing(2),
    },
  }
))
  
  

  
  const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    },
  
    
    
  }
    
  )




function TextInput(props){
    const classes = useStyles();
    
    // SETAR VALORES NOS INPUTS


    const [valueTf,setValueTf] = useState(props.defaultValue);
    const [iconload,setIconLoad] = useState();

    function setValue(w){
      if(props.cnpj){
        w = w.replace(/\D/g, "");
      
        let neww = '';
        for(let i=0;i<w.length;i++){
          neww += w[i];
          if(i===1){
            neww+='.';
          }
          if(i===4){
            neww+='.';
          }
          if(i===7){
            neww+='/';
          }
          if(i===11){
            neww+='-';
          }
        }
        
        w = neww;
      }


      if(props.cpf){
        w = w.replace(/\D/g, "");
       
        let neww = '';
        for(let i=0;i<w.length;i++){
          neww += w[i];
          if(i===2){
            neww+='.';
          }
          if(i===5){
            neww+='.';
          }
          if(i===8){
            neww+='-';
          }
          
        }
        w = neww;
      }






      setValueTf(w)
    }

    // SALVAR VALORES INPUT
    
    function saveForm(v,id,kind,sheet,col){
      
      if(kind==="perc"){
        v = v.replace('%','');
            if(v>=0){
              v=v+'%'
            }else{
              alert('Percentual inválido. Valor setado para 0%.');
              v='0%';
            }
        }

        if(kind==="money"){
          v = v.replace('R$','');
          if(v>=0){
            v='R$ '+v
          }else{
            alert('Valor inválido. O número foi zerado.');
            v='R$ 0';
          }
        }

        



      setValueTf(v);

      v=v.trim();

      if(kind==="nr"){
        v = v.replace(/\D/g, "");
        
      }

      if(kind==="perc"){ v = v.replace('%',''); }
      if(kind==="money"){ v = v.replace('R$ ',''); }
      if(kind==="cnpj"){ v = v.replace(/\D/g, ""); }
      if(kind==="cpf"){ v = v.replace(/\D/g, ""); }
     

      setIconLoad(<CloudUploadIcon color="secondary"/>)
              setTimeout(() => {
              setIconLoad()
      }, 600);

      updateBD(v,id,sheet,col);


    };

    return <TextField  
    fullWidth={true}
    variant="outlined"
    color="primary"
    autoComplete='off'
    type={props.type}
    label={props.label}
    defaultValue={props.defaultValue}
    placeholder={props.placeholder}
    value={valueTf}
    onFocus={event => {
      event.target.select();
    }}
    onBlur={e => {
      saveForm(e.target.value,props.id,props.kind,props.sheet,props.col);
      if(props.atualiza){
        props.update();
      }
    }}
    onChange={e => {
      props.login ?
        props.setValue(e.target.value)
     :
        setValue(e.target.value);
      
    }}
    className={classes.root}
    InputProps={{
      readOnly: props.ro,
      
      style: { 
        height:0,
        padding: '26px 0px',color:'white',placeholder:'grey'},  

        endAdornment: (
          <InputAdornment position="start">
          
           {iconload}
          
        </InputAdornment>

        )
    }}
   
    
    />;
  
  }






  function Multiline(props){
    const classes = useStyles();
    
    // SETAR VALORES NOS INPUTS


    const [valueTf,setValueTf] = useState(props.defaultValue);
    const [iconload,setIconLoad] = useState();

    function setValue(w){
      setValueTf(w)
    }

    // SALVAR VALORES INPUT
    
    function saveForm(v,id,kind,sheet,col){
      
      
      setValueTf(v);
      if(kind==="perc"){ v = v.replace('%',''); }
      if(kind==="money"){ v = v.replace('R$ ',''); }
      if(kind==="cnpj"){ v = v.replace(/\D/g, ""); }
      
      
      setIconLoad(<CloudUploadIcon color="secondary"/>)
              setTimeout(() => {
              setIconLoad()
      }, 600);


      updateBD(v,id,sheet,col);


    };

    return <TextField  
    fullWidth={true}
    variant="outlined"
    color="primary"
    multiline
    minrows={4}
    type={props.type}
    label={props.label}
    defaultValue={props.defaultValue}
    placeholder={props.placeholder}
    value={valueTf}
    onFocus={event => {
      event.target.select();
    }}
    onBlur={e => {
      saveForm(e.target.value,props.id,props.kind,props.sheet,props.col);
    }}
    onChange={e => {
      props.login ? props.setValue(e.target.value) : setValue(e.target.value)
      
    }}
    className={classes.root}
    InputProps={{
      readOnly: props.ro,
      style: { 
        height:0,
        padding: '26px 0px',color:'white',placeholder:'grey'},  

        endAdornment: (
          <InputAdornment position="start">
          
           {iconload}
          
        </InputAdornment>

        )
    }}

    
    
    />;
  
  }




function TextInputNotSave(props){
  const classes = useStyles();
  

  const [valueTf] = useState(props.defaultValue)

  

  return <TextField  
  fullWidth={true}
  variant="outlined"
  color="primary"
  type={props.type}
  label={props.label}
  defaultValue={props.defaultValue}
  placeholder={props.placeholder}
  value={valueTf}
  onFocus={event => {
    event.target.select();
  }}
  
  onChange={e => {
    
    props.setValue(e.target.value)
   
  }}


  className={classes.root}
  InputProps={{
    readOnly: props.ro,
    style: { 
      height:0,
      padding: '25px 0px',color:'white',placeholder:'grey'},  
  }}
  />;

}



  function MoneyInput(props){
    const classes = useStyles();
   
    // SETAR VALORES NOS INPUTS

    const [valueTf,setValueTf] = useState(props.defaultValue)

    function setValue(w){
      
      setValueTf(w)
    }

    // SALVAR VALORES INPUT
            
    function saveForm(v,id,kind,sheet,col){
      
      v = v.replace(',','');
       
      setValueTf(v)
      updateBD(v,id,sheet,col)
      
    };

    return <CurrencyTextField  
    fullWidth={true}
    variant="outlined"
    color="primary"
    type={props.type}
    label={props.label}
    onFocus={event => {
      event.target.select();
    }}
    defaultValue={props.defaultValue}
    placeholder={props.placeholder}
    className={classes.root}
    onBlur={e => {
      saveForm(e.target.value,props.id,props.kind,props.sheet,props.col);
    }}
    InputProps={{
      readOnly: props.ro,
      style: { 
        height:0,
        padding: '25px 10px', } 
    }}
    value={valueTf}
        currencySymbol="R$"
        outputformat="number"
        onChange={(event, value)=> setValue(value)}
    />;
  
  }

  


function MyDatePicker(props) {
  const classes = useStyles();
    const [selectedDate, setSelectedDate] = React.useState(
      new Date()
    )

    const handleDateChange = (date) => {
      if(props.save===1){
        // save BD
      }else{
        props.onChange(date)
      }
      
      setSelectedDate(date)
    }

    
  return (
    <div>
      <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      
      <KeyboardDatePicker
        fullWidth={true}
        disableToolbar
        autoOk
        variant="inline"
        color="secondary"
        inputVariant="outlined"
        label={props.label}
        views={props.view}
        format={props.format ? props.format : "dd/MM/yyyy"}
        value={props.startDate ? parseISO(props.startDate) : selectedDate}
        InputAdornmentProps={{ position: "start" }}
        onChange={date => handleDateChange(date)}
        className={classes.root}
        minDate={props.minDate ? new Date(props.minDate) : new Date(2020,0,1)}
        
        InputProps={{
          readOnly: props.ro,
          style: { 
            height:0,
            
            padding: '25px 10px', } 
        }}
        KeyboardButtonProps={{
          'aria-label':'change  data'
        }}
      />

     
    
      </MuiPickersUtilsProvider>
      </ThemeProvider>
      </div>
  );
}

function MyDateTimePicker(props) {
 
    const [selectedDate, setSelectedDate] = React.useState(
      new Date()
    )

    const handleDateChange = (date) => {
      if(props.save===1){
        // save BD

        updateBD(date,props.id,props.sheet,props.col)
      }else{
        props.onChange(date)
      }
      
      setSelectedDate(date)
    }

    
  return (
    <div>
      <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      

 

<DateTimePicker value={props.startDate ? parseISO(props.startDate) : selectedDate} fullWidth={true} variant="inline" color="secondary" inputVariant="outlined" label={props.label} format="dd/MM/yyyy hh:mm a" onChange={date => handleDateChange(date)}/>
      

     
    
      </MuiPickersUtilsProvider>
      </ThemeProvider>
      </div>
  );
}




function MySelect(props){

  const classes = useStyles();
  const [selected, setSelected] = React.useState(props.initValue);

  const handleChange = (event) => {
  
    
    setSelected(event.target.value);

    if(props.save===1){
      updateBD(event.target.value,props.id,props.sheet,props.col);
    }else{
      props.change(event.target.value)
    }
    
  };
  
  return (
    
        <TextField
        fullWidth={true}
        className={classes.root}
        InputProps={{
          readOnly: props.ro,
          style: { 
            height:0,
            padding: '25px 10px',},
            
            
        }}
          select
          value={selected}
          label={props.label}
          onChange={handleChange}
          variant="outlined"
          placeholder="Selecione"
        >
          <MenuItem value={0}>{props.first_item ? props.first_item : 'Selecione'}</MenuItem>
          <MenuItem><div className="px bgcolor_6"></div></MenuItem>
          {props.list.map((option,objectID) => (
            <MenuItem key={objectID} value={option.value}>
              {option.label}
            </MenuItem>
          ))}

        </TextField>
        
        
        )}



        const handleMouseDown = (event) => {
          event.preventDefault();

        };



        function TextInputDelete(props){
          const classes = useStyles();

          // SETAR VALORES NOS INPUTS


            const [valueTf,setValueTf] = useState(props.defaultValue)
            const [iconload,setIconLoad] = useState(<Delete/>);

            function setValue(w){
              
              setValueTf(w)
            }

            // SALVAR VALORES INPUT
            
            function saveForm(v,id,kind,sheet,col){
              
              if(kind==="perc"){
                v = v.replace('%','');
                    if(v>=0){
                      v=v+'%'
                    }else{
                      alert('Percentual inválido.');
                  }
                }

              setValueTf(v)

              setIconLoad(<CloudUploadIcon color="secondary"/>)
              setTimeout(() => {
                setIconLoad(<Delete/>)
              }, 600);

              updateBD(v,id,sheet,col)
              

            };

           

            return (
              <ThemeProvider theme={theme}>
              <OutlinedInput 


                    fullWidth="true"
                    className={classes.root}
                    type={props.type}
                    label={props.label}
                    defaultValue={props.defaultValue}
                    placeholder={props.placeholder}
                    value={valueTf}
                    onFocus={event => {
                      event.target.select();
                    }}
                    onBlur={e => {
                      saveForm(e.target.value,props.id,props.kind,props.sheet,props.col);
                      
                    }}
                    onChange={e => {
                      setValue(e.target.value);
                      
                    }}
                  

                    readOnly={props.ro}
                    
                    
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={props.clickFunction}
                          onMouseDown={handleMouseDown}
                          edge="end"
                        >
                    
                         {props.ro ? "" : iconload }
                         

                        </IconButton>
                      </InputAdornment>
                    }
                    />
                </ThemeProvider>
            )

            
        }





        function AutoComp(props){



          const classes = useStyles();
          const [list,setList] = useState([{id:"0",title:"Digite para buscar"}]);
         
          function change(w,u){
            
            if(w.length>1){
              api.get('/get/autoComplete.php?sheet='+props.sheet+'&empresa_id='+props.empresa_id+'&tipo='+props.tipo+'&term='+w+'&r='+Math.random(10000)).then(result => {
                let res = result.data;
               
                for(let i=0;i<res.length;i++){
                  res[i]['title']= res[i]['title'].replace('  ',' ').trim();
                  
                }
                setList(res)
              })
            }

            

          }

            return (
              <ThemeProvider theme={theme}>
              <Autocomplete

                    autoComplete={false}
                    fullWidth={true}
                    className={classes.root}
                    options={list}
                    getOptionLabel={(option) => option.title}
                    value={props.value}
                    onKeyPress={(event) => {
                      change(event.target.value,'users')
                      
                    }}
                    InputProps={{
                      style: { 
                        height:10,
                        padding: '25px 10px',},
                        
                        
                    }}
                    onClose={(newValue) => {
                      
                      
                     if(newValue.target.outerText){
                       
                        props.change(list.filter(us => us.title === newValue.target.outerText)[0]['id'],props.tipo);
                      }
                      
                    }}

                    onBlur={(event) => {
                        props.blur(event.target.value)
                      
                    }}
              
                    
                    renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" />}
                    
                    />
                </ThemeProvider>
            )

            
        }


        function AutoCompFree(props){
          const classes = useStyles();
          const [list,setList] = useState([{id:"0",title:"Digite para buscar"}]);
         
          function change(w){

            if(w.length>1){
              api.get('/get/autoComplete.php?sheet='+props.sheet+'&tipo='+props.tipo+'&term='+w+'&r='+Math.random(10000)).then(result => {
              setList(result.data)
              
              })
            }

          }

            return (
              <ThemeProvider theme={theme}>
              <Autocomplete
                    autoComplete={false}
                    freeSolo
                    fullWidth={true}
                    className={classes.root}
                    options={list}
                    getOptionLabel={(option) => option.title}
                    value={props.value}
                    onKeyPress={(event) => {
                      change(event.target.value)
                    }}
                    onChange={e => {
                      props.change(e);
                    }}
                    onBlur={e => {
                      props.blur(e.target.defaultValue);
                    }}
                    
                    renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" />}
                    
                    />
                </ThemeProvider>
            )

            
        }



        

        function AutoCompSelect(props){
          const classes = useStyles();
          
          
            return (
              <ThemeProvider theme={theme}>
                
              <Autocomplete
                    autoComplete={false}
                    fullWidth={true}
                    className={classes.root}
                    options={props.list}
                    getOptionLabel={(option) => option.label}
                    InputProps={{
                      style: { 
                        height:10,
                        padding: '15px 10px',},
                        
                        
                    }}
                    onClose={(newValue) => {
                     
                      if(newValue.target.innerText){
                       
                        if(props.notSave){
                          
                          props.change(props.list.filter(us => us.label === newValue.target.innerText)[0]['id']);
                        }else{
                          
                          updateBD(props.list.filter(us => us.label === newValue.target.innerText)[0]['id'],props.id,props.sheet,props.col);
                        }
                        
                      }
                      
                    }}
                    
                    renderInput={(params) => <TextField {...params} label={props.label} variant="outlined"/>}
                    
                    />

                </ThemeProvider>
            )

            
        }



        function MySwitch(props){
          
          const [confirmado,setConfirm] = useState(props.checked/1);

          function handleChange(event,v){
            
            if(event.target.checked===false){
            v=0;
            }else{
            v=1;
            }
          
            if(props.userAtivo===1){
              v=1-v;
            }
    
            console.log('update/update.php?value='+v+'&id='+props.id+'&sheet='+props.sheet+'&col='+props.col+'&r='+Math.random(10000))
            api.get('update/update.php?value='+v+'&id='+props.id+'&sheet='+props.sheet+'&col='+props.col+'&r='+Math.random(10000))
            setConfirm(event.target.checked);
    
        };

          return (
          <FormControlLabel
                        control={<Switch checked={confirmado} onChange={(e)=>handleChange(e)} color="primary" name={props.nome} />}
                        label={props.nome}
          />
          )

        }


export { TextInput,MoneyInput,MyDatePicker,MyDateTimePicker,MySelect,TextInputDelete,TextInputNotSave,AutoComp,AutoCompSelect,AutoCompFree,MySwitch,Multiline};