import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import {TextInput,MoneyInput,AutoCompSelect, MySelect, MySwitch} from '../../components/textField';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import Imgupload from './imgUpload';

import './style.css';



const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },

}
  
)

 
export default class List extends Component {


  constructor(props){
      super();
    
      this.state = {
            userid:props.id,
            token: localStorage.getItem("tokens"),
            empresa_id: localStorage['empresa_id'],
            usuario_id: localStorage['usuario_id'],
            permission: localStorage['permission'],
            ativo:props.ativo/1,
            loading:true,
            loadPerc:0,
            empresas:[],
            listSkill:[],
            listUser:[],
            optionUsers: [],
            selected:0,
            permissao: [{"value":"0","label":"Admin"},{"value":"1","label":"Full"},{"value":"2","label":"Budget"},{"value":"3","label":"Artist"}],
            overhead: [{"value":"0","label":"Overhead"},{"value":"1","label":"Horas Vendáveis"}],
            acordo: [{"value":"0","label":"Nenhum"},{"value":"1","label":"Contínuo"},{"value":"2","label":"Fechamento mensal"},{"value":"3","label":"Fechamento trimestral"},{"value":"4","label":"Fechamento semestral"}],
            contrato: [{"value":"0","label":"PJ"},{"value":"1","label":"CLT"}],
            cc_tipo:[{"value":"0","label":"PF"},{"value":"1","label":"PJ"}],
            cargos:[{"value":"0","label":"Diretor"},{"value":"1","label":"Produtor Executivo"},{"value":"2","label":"Atendimento"},{"value":"3","label":"Coordenador"},{"value":"6","label":"GP"},{"value":"4","label":"Financeiro"},{"value":"5","label":"Artista"},{"value":"5","label":"Estagiario"}],
            classes: ({
            root: {
                flexGrow: 0,   
            },
            paper: {
                textAlign: 'center',
                color: theme.palette.text.secondary,
                padding: 0,
                margin: -2,   
            },
            control: {
                padding: 10,
                marginTop: 5,
                background: "#fc3"
            }
            })
        }
     
  }
  

 

  
  
  async componentDidMount(){
 
  this.getUnidades()
  
    
  }

  // LISTA DAS UNIDADES

  getUnidades = async () => {
    this.toLoad()
    
    api.get('get/getSelect.php?sheet=unidades&id='+this.state.empresa_id+'&empresa_id='+this.state.empresa_id+'&col_id=empresa_id&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({listUnidades: result.data})
         
          this.getSuperior()
      }});

  }


  // SUPERIOR

  getSuperior = async () => {
    this.toLoad()
    
    api.get('get/getSelect.php?sheet=users&id=0&empresa_id='+this.state.empresa_id+'&col_id=tipo&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
          this.setState({listSuperior: result.data})
         
          this.getUser()
      }});

  }


  // LABEL UNIDADE

  getUnidadeLabel = (w) =>{
    
    let unidades = this.state.listUnidades;
    let label = '';
   
    for(let i=0;i<unidades.length;i++){
        
        if(unidades[i]['id']===w){
            label = unidades[i]['label'];
        }
    }
    
    return label;
  }

  // LISTA DO USER

  getUser = async () => {
    this.toLoad()
   
    api.get('get/get.php?sheet=users&id='+this.state.userid+'&col_id=id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
         
       
          this.setState({listUser: result.data})
          
            setTimeout(() => {
                this.getEmpresas();
            }, 10);
          
          
      }});

  }


// EMPRESAS E ACESSOS

  getEmpresas = () => {


    api.get('get/getEmpresas.php?id=0&sheet=empresa&col_id=active&r='+Math.random(10000)).then(result => {
      
        if (result.status === 200) {
          

            let empresas = result.data;

            let acessos = this.state.listUser[0].acessos.split(',');

            
            for(let i=0;i<empresas.length;i++){
                empresas[i]['acesso']=0
                if(acessos.indexOf(empresas[i]['id'])>-1){
                    empresas[i]['acesso']=1
                }

            }
            for(let i=0;i<empresas.length;i++){
            if(empresas[i]['id']===this.state.empresa_id){
                empresas.splice(i,1)
            }
        }
            
            
            this.setState({
                empresas: empresas
            
            });


            this.endLoad();

        }});
    

  }


  // ADD ACESSO

  addAcesso = (oid) => {

    let empresas = this.state.empresas;

    empresas[oid].acesso = 1 - empresas[oid].acesso;

    let acesso = [];

    for(let i=0;i<empresas.length;i++){
        if(empresas[i].acesso===1){
        acesso.push(empresas[i].id)
        }
    }

    acesso = acesso.join(',');

    
    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+acesso+'&id='+this.state.userid+'&sheet=users&col=acessos&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {
            this.setState({
                empresas: empresas
            
            });
        }})


    



  }

  // TOLOAD

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
  

  endLoad = () => {
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () => {
    if(this.state.loadPerc<100){
            this.setState({
                loadPerc:this.state.loadPerc*1.6,
            })
        }
    }


  // CHANGE FIELD

  changeNew = (e,w) => {
   
    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+e+'&id='+this.state.listUser[0]["id"]+'&sheet=users&col='+w+'&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {

            
            let user = this.state.listUser;

            user[0][w] = e;

            
            this.setState({
                listUser:user
            })


        }})

  }

  void = () => {}

  no = () => {
      alert('Você não tem permissão para alterar esta informação.')
  }
   

  render(props){

    const classes = this.state.classes;

   
  
      
  

  return (

    
    <ThemeProvider theme={theme}>
        <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
      {this.state.listUser.map((user,objectID)=>(

      <div key={objectID} className={classes.root}>
          <div className="mt20 mb20">
          <Grid container spacing={3}>
          
            <Grid item xs={3}><Imgupload foto={user.foto} id={user.id} sheet="users" /></Grid>
            
            <Grid item xs={9}>

            <Grid container spacing={3}>
               
            <Grid item xs={3}> 
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="nome"
                            type="text"
                            label="Nome"
                            fullWidth="true"
                            defaultValue={user.nome}
                            ro={this.state.permission/1<2 ? false : true}
                        />
            </Grid>

            <Grid item xs={3}> 
                    
                    <TextInput 
                        kind="email"
                        id={user.id}
                        sheet="users"
                        col="email"
                        type="text" 
                        label="E-mail"
                        fullWidth="true"
                        defaultValue={user.email}
                        ro={this.state.permission/1<2 ? false : true}
                    />
                
                </Grid>

                <Grid item xs={3}> 
              
                    
                    
              <TextInput 
                  kind="text"
                  id={user.id}
                  sheet="users"
                  col="senha"
                  type="password" 
                  label="Senha"
                  fullWidth="true"
                  defaultValue={user.senha}
              />
         
         </Grid>

         <Grid item xs={3}> 
             
                    
                    
                   
             <TextInput 
                 kind="text"
                 id={user.id}
                 sheet="users"
                 col="fone"
                 type="text" 
                 label="Telefone"
                 fullWidth="true"
                 defaultValue={user.fone}
             />
         </Grid>

        


         <Grid item xs={3}> 
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="razao_social"
                            type="text" 
                            label="Razão Social"
                            fullWidth="true"
                            defaultValue={user.razao_social}
                            ro={this.state.permission/1<2 ? false : true}
                        />
                    </Grid>
 <Grid item xs={3}> 
                    
                        <TextInput
                            cnpj={true} 
                            kind="nr"
                            id={user.id}
                            sheet="users"
                            col="cnpj"
                            type="text" 
                            label="CNPJ"
                            fullWidth="true"
                            defaultValue={user.cnpj}
                            ro={this.state.permission/1<2 ? false : true}
                        />
                    

                   
                    </Grid>

                    <Grid item xs={3}> 
                   
                   <TextInput 
                       cpf={true} 
                       kind="nr"
                       id={user.id}
                       sheet="users"
                       col="cpf"
                       type="text" 
                       label="CPF"
                       fullWidth="true"
                       defaultValue={user.cpf}
                       ro={this.state.permission/1<2 ? false : true}
                   />
                </Grid>
                

                   
            <Grid item xs={3}> 
                        <AutoCompSelect 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="contrato"
                            type="text" 
                            fullWidth="true"
                            label={this.state.contrato ? this.state.contrato[user.contrato]['label'] : ""}
                            list={this.state.contrato}
                            onLoad={this.toLoad} 
                            endLoad={this.endLoad}
                            change={(e)=>this.change(e)}
                            value={{label:this.state.contrato[user.contrato]['label'],value:user.contrato}}
                            
                        />
                   
                   </Grid>


                    <Grid item container spacing={2} xs={3}> 
                    <Grid item xs={8}> 
                        <MoneyInput 
                            kind="text"
                            id={this.state.permission/1<2 ? user.id : 0}
                            sheet="users"
                            col="salario"
                            type="text" 
                            label="Salário"
                            fullWidth="true"
                            defaultValue={this.state.permission/1<2 ? user.salario : 0}
                            ro={this.state.permission/1<2 ? false : true}
                        />
                        </Grid>
                        <Grid item xs={3}> 

                        <MySwitch checked={1-user.treze/1} userAtivo={1} onChange={this.state.permission/1<2 ? this.handleChange : this.void()} nome="13º" id={user.id} sheet="users" col="treze" />
                         </Grid>
                    </Grid>

                    <Grid item xs={3}> 
                        <MoneyInput 
                            kind="text"
                            id={this.state.permission/1<2 ? user.id : 0}
                            sheet="users"
                            col="variavel"
                            type="text" 
                            label="Gatilho"
                            fullWidth="true"
                            defaultValue={this.state.permission/1<2 ? user.variavel : 0}
                            ro={this.state.permission/1<2 ? false : true}
                        />
                    
                    </Grid>


                    <Grid item xs={3}> 
                   
                   <MySelect list={this.state.acordo} initValue={user.acordo} label="Acordo variável" change={this.state.permission/1<2 ? (e)=>this.changeNew(e,"acordo") : ()=>this.no()}/>
                   
                   </Grid>

                   

                   <Grid item xs={3}> 

                   
                    <TextInput 
                        kind="text"
                        id={user.id}
                        sheet="users"
                        col="pix"
                        type="text" 
                        label="PIX"
                        fullWidth="true"
                        ro={this.state.permission/1<2 ? false : true}
                        defaultValue={user.pix}
                    />

                    </Grid>

                   <Grid item xs={12}>

                  
                        <TextInput 
                            kind="text"
                            id={this.state.permission/1<2 ? user.id : 0}
                            sheet="users"
                            col="bonus"
                            type="text" 
                            label="Bonus"
                            fullWidth="true"
                            ro={this.state.permission/1<2 ? false : true}
                            defaultValue={this.state.permission/1<2 ? user.bonus : ''}
                        />
                    
                    
                    
                    </Grid>

                    <Grid item xs={3}> 
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="banco"
                            type="text" 
                            label="Banco"
                            fullWidth="true"
                            defaultValue={user.banco}
                        />
                     </Grid>

                     <Grid item xs={3}> 
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="agencia"
                            type="text" 
                            label="Agência"
                            fullWidth="true"
                            defaultValue={user.agencia}
                        />
                     </Grid>

                     <Grid item xs={3}> 
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="conta_corrente"
                            type="text" 
                            label="Conta Corrente"
                            fullWidth="true"
                            defaultValue={user.conta_corrente}
                        />
                     </Grid>

                     <Grid item xs={3}> 
                     <MySelect list={this.state.cc_tipo} initValue={user.cc_tipo} label="CC Tipo" change={this.state.permission/1<2 ? (e)=>this.changeNew(e,"cc_tipo") : ()=>this.no()}/>
                  
                     </Grid>

                  

                   <Grid item xs={3}> 

                    <MySelect list={this.state.permissao} initValue={user.permissao} label="Permissão" change={this.state.permission/1<2 ? (e)=>this.changeNew(e,"permissao") : ()=>this.mo()}/>
 
 </Grid>

 <Grid item xs={3}> 
                    <MySelect list={this.state.listUnidades} initValue={user.unidade_id} label="Unidades" change={this.state.permission/1<2 ? (e)=>this.changeNew(e,"unidade_id") : ()=>this.mo()} />
                        
                    </Grid>


            
            
           
           
                    <Grid item xs={3}> 
               

                 
                    <MySelect list={this.state.cargos} initValue={user.cargo} label="Tipo do Cargo" change={this.state.permission/1<2 ? (e)=>this.changeNew(e,"cargo") : ()=>this.no()}/>
                    
                   
                    </Grid>

                    <Grid item xs={3}> 
               

                 
                    <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="nome_cargo"
                            type="text" 
                            label="Nome do cargo"
                            fullWidth="true"
                            ro={this.state.permission/1<2 ? false : true}
                            defaultValue={user.nome_cargo}
                        />
                    
                   
                    </Grid>

                    <Grid item xs={3}> 
               

                 
                    <MySelect list={this.state.listSuperior ? this.state.listSuperior : []} initValue={user.superior} label="Superior direto" change={this.state.permission/1<2 ? (e)=>this.changeNew(e,"superior") : ()=>this.no()}/>
                    
                   
                    </Grid>

                    <Grid item xs={3}> 
                
                        <TextInput 
                            kind="text"
                            id={user.id}
                            sheet="users"
                            col="aniversario"
                            type="text" 
                            label="Aniversário"
                            fullWidth="true"
                            defaultValue={user.aniversario}
                        />

                        </Grid>



                   
                    <Grid item xs={3}>
                        <div className="mt10">

                            <MySwitch checked={1-user.active/1} userAtivo={1} onChange={this.state.permission/1<2 ? this.handleChange : this.void()} nome="Ativo" id={user.id} sheet="users" col="tipo" />
                            

                        </div>
                    </Grid>
                   
                    {this.state.usuario_id/1===369 || this.state.usuario_id/1===16471 || this.state.usuario_id/1===62 ? 
                    <Grid item xs={12}>
                        <div className="mini-titulo mb10">Acessos nas empresas</div>
                        <Grid container spacing={1}>
                            
                            {this.state.empresas.map((empresa,objectID)=>(
                                <Grid item xs={2} key={objectID}>
                                        <div onClick={(oid)=>this.addAcesso(objectID)} className={empresa.acesso/1===1 ? "wp100 pd10 bgcolor_1 tc br5 cl nano-titulo" : "wp100 pd10 bgcolor_6 tc br5 cl nano-titulo"}>{empresa.nome}</div>
                                    </Grid>
                            
                            ))}

                        </Grid>
                    </Grid>
                    :''}

                    </Grid>

            </Grid>
  
            
            </Grid>

            </div>
      </div>
      ))}

    </ThemeProvider>

  )
}

}